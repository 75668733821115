import React from 'react';
import { Hat } from 'components/Hat/Hat';
import { Seo, Feed } from 'components';
import capitalize from 'capitalize';

export default ({ pageContext: { tag, feed } }) => {
  const isNeededCapitalize = tag.split('')[0].toUpperCase() !== tag.split('')[0];

  const title = isNeededCapitalize ? capitalize(tag) : tag;

  return (
    <>
      <Seo title={title} description={`${title} — Digital-agency Nimax`} />
      <Hat title={title} as={'h1'} pageType={tag} />
      <Feed
        list={feed.map((item) => {
          return item;
        })}
        tag={[tag]}
      />
    </>
  );
};
